import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/hegel/hegel/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "optional-types"
    }}>{`Optional Types`}</h1>
    <hr></hr>
    <p>{`Sometimes you need to include undefined to defined variable/object property. Optional type is a solution. It is not some separated type. It's special syntax for type that adds to any type additional value - undefined. You can create them by adding a question mark in front of the type name.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const givenAge = -5;
const userAge: ?number = givenAge > 0 ? givenAge : undefined;
`}</code></pre>
    <p><strong parentName="p">{`Warning`}</strong>{`: Unlike Flow.js, Hegel will throw an error if you try to assign `}<inlineCode parentName="p">{`null`}</inlineCode>{` as value of an optional type`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`// Error: Type "null" is incompatible with type "number | undefined"
const someValue: ?number = null;
`}</code></pre>
    <p>{`Optional type is not only about undefined variables or object properties.
You can use it inside function argument definition to annotate optional argument.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`function doSomething(optionalArg: ?number) {}

doSomething(); // 👌!
doSomething(42); // 👌!
doSomething(undefined); // 👌!

// Error: Type "null" is incompatible with type "number | undefined"
doSomething(null);
`}</code></pre>
    <p>{`Also you can use it inside object type definition to annotate optional property.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`let user: { name: ?string } = { name: "Arya Stark" };

user.name = undefined; // 👌!
user.name = "Arya Stark"; // 👌!

// Error: Type "null" is incompatible with type "number | undefined"
user.name = null;

user = {}; // 👌!
user = { name: "Arya Stark" }; // 👌!
user = { name: undefined }; // 👌!

// Error: Type "{ name: null }" is incompatible with type "{ name: string | undefined }"
user = { name: null };
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      